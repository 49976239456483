import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import translations from "../translations"
import buildingImage from "../images/black_building_small.jpg"
import aboutUs1Image from "../images/about_us_1.jpg"
import aboutUs2Image from "../images/about_us_2.jpg"

const AboutPage = ({ pathContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={translations[locale].aboutUsPage.header} />
    <div className="page">
      <div className="container center">
        <h2 className="page-title">{translations[locale].aboutUsPage.header}</h2>
      </div>
      <div className="container row">
        <p className="two-third" dangerouslySetInnerHTML={{__html: translations[locale].aboutUsPage.firstParagraph}} />
        <div className="one-third">
          <img src={buildingImage} alt="building" />
        </div>
      </div>
      <div className="container row no-padding center">
        <p className="cite">{translations[locale].aboutUsPage.cite}</p>
      </div>
      <div className="container row no-padding">
        <p dangerouslySetInnerHTML={{__html: translations[locale].aboutUsPage.secondParagraph}} />
      </div>
      <div className="container row">
        <div className="half">
          <img src={aboutUs1Image} alt="management board" />
        </div>
        <div className="half">
          <img src={aboutUs2Image} alt="management board" />
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
